import React from 'react'
import * as productCodes from '@/constants/product-codes'

import styles from './ProductAccordion.module.scss'

const accordionData = {
    [`${productCodes.LOOM_AND_LEAF}`]: [
        {
            title: 'Eco-friendly foams',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>We substitute 30% of the polyurethane in our foams with renewable, naturally-derived soybean & corn oils</li>
                    <li>The manufacturing of our foams produce less greenhouse gasses than traditional polyurethane foam</li>
                </ul>
            )
        },
        {
            title: 'Certified free of harmful chemicals',
            content: (
                <ul>
                    <li>CertiPUR-US® certified free of ozone depleters, PBDEs, TDCPP, and TCEP flame retardants, mercury, lead, and other heavy metals, formaldehyde, and phthalates</li>
                    <li>Low volatile organic compounds (VOCs) means no off-gassing fumes when your mattress arrives, so you can breathe easy</li>
                </ul>
            )
        },
        {
            title: 'Lower carbon footprint',
            content: (
                <ul>
                    <li>Each mattress is made fresh to order in one of our 19 U.S. factory partners</li>
                    <li>Your mattress travels less than a 100 miles on average from our factory to your home</li>
                </ul>
            )
        }],
    [`${productCodes.SAATVA_CONTOUR5}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Patented Lumbar Zone® PCM Matrix</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Strategically positioned at the center third of the mattress, where most of your body weight is focused, to regulate temperature, absorb and disperse excess body heat, and deliver enhanced lower back support.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Gel-infused high-density 4lb memory foam</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Body-contouring comfort meets ultimate cooling with our gel infusion and ventilated airflow channels throughout the 1.5” memory foam to help you stay at optimal temperature for restful sleep.</li>
                </ul>
            )
        }],
    [`${productCodes.ZENHAVEN}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_VOTIVES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_CANDLES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.CRIB_MATTRESS}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_HD}`]: [
        {
            title: 'Zoned firmness',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>5 ergonomic zones designed to give you enhanced lumbar support in the center third of the mattress</li>
                    <li>Part of our Lumbar Zone® Technology, endorsed by the Congress of Chiropractic State Association</li>
                </ul>
            )
        },
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.THE_SAATVA_PILLOW}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                </ul>
            )
        }
    ],
    [`${productCodes.LATEX_MATTRESS_TOPPER}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.ORGANIC_QUILTED_PILLOW}`]: [
        {
            title: 'Durability',
            content: (
                <ul>
                    <li>Natural latex is the most durable mattress foam type on the market</li>
                    <li>Outlasts polyurethane foams and other synthetic foams</li>
                </ul>
            )
        },
        {
            title: 'Excellent pressure relief',
            content: (
                <ul>
                    <li>Relieves pressure points for better sleep and less back and joint pain</li>
                    <li>Provides a unique buoyant feel and responsive support</li>
                </ul>
            )
        },
        {
            title: 'Superb breathability',
            content: (
                <ul>
                    <li>Natural latex is more breathable than synthetic foams</li>
                    <li>Vented airflow channels add even more breathability</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SCENTED_VOTIVES}`]: [
        {
            title: 'Unwind',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Ylang Ylang + Neroli</li>
                    <li>Calms the mind for peaceful bliss</li>
                </ul>
            )
        },
        {
            title: 'Drift',
            content: (
                <ul>
                    <li>Lavender + Vetiver</li>
                    <li>Eases tension and invites relaxation</li>
                </ul>
            )
        },
        {
            title: 'Dream',
            content: (
                <ul>
                    <li>Cedarwood + Vanilla</li>
                    <li>Grounds the senses for restful sleep</li>
                </ul>
            )
        },
        {
            title: 'Restore',
            content: (
                <ul>
                    <li>Chamomile + Bergamot</li>
                    <li>Soothes the mind and body</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SOLAIRE}`]: [
        {
            title: 'Firm',
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Increases the air chamber firmness</li>
                    <li>Press & hold until you reach the setting that feels best</li>
                </ul>
            )
        },
        {
            title: 'Bedfill',
            content: (
                <ul>
                    <li>Fully inflates each air chamber during first use (Note: Each air chamber can only be filled one at a time)</li>
                </ul>
            )
        },
        {
            title: 'Soft',
            content: (
                <ul>
                    <li>Decreases the air chamber firmness</li>
                    <li>Press & hold until you reach the setting that feels best</li>
                </ul>
            )
        },
        {
            title: 'Memory',
            content: (
                <ul>
                    <li>Press & hold for 5 seconds to save your preferred firmness setting</li>
                    <li>Press once to display your stored firmness setting</li>
                    <li>Press twice to fill Solaire to your stored firmness setting</li>
                </ul>
            )
        }
    ],
    [`${productCodes.SAATVA_RX}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Rx Foam Module</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>2" foam modules encased on top of each individual coil virtually eliminates motion transfer for more undisturbed sleep.</li>
                    <li>Our proprietary foam is infused with graphite and phase-change material to help regulate body temperature, keeping you cool and comfortable all night.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>8" Comfort Coil Unit</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Triple-tempered recycled steel springs work in tandem with the foam modules above to provide maximum contouring and pressure relief for sleepers with serious chronic back & joint issues.</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Quantum-Edge Coils</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Firmer caliper spring coils around the perimeter of the mattress offer excellent edge support for better mobility while getting in and out of bed.</li>
                </ul>
            )
        }
    ],
    [`${productCodes.DOG_BED}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Spill & stain repellent, high-performance fabric cover</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Removable & durable cover repels liquids & stains for easy cleanup, while staying breathable</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>Plush comfort fibers</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Pillow top layer is baffle quilted inside for even distribution of cuddle-worthy softness</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Micro-coil support core</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Offers buoyant, responsive support to keep your pet’s spine in healthy alignment and adds to the bed’s breathability</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>4</span>
                    <span>Non-slip base</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Helps prevent the bed from slipping and sliding around</li>
                </ul>
            )
        }
    ],
    [`${productCodes.MEMORY_FOAM_HYBRID}`]: [
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>1</span>
                    <span>Cooling gel-infused memory foam lumbar crown</span>
                </span>
            ),
            isOpenOnRender: true,
            content: (
                <ul>
                    <li>Draws excess heat away from the sleep surface like a magnet</li>
                    <li>Prevents overheating so you stay asleep longer</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>2</span>
                    <span>AirCradle™ memory foam</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Responds to your body’s movements and pressure</li>
                    <li>Circulates airflow through the foam’s unique wave design</li>
                </ul>
            )
        },
        {
            title: (
                <span>
                    <span className={styles.productAccordion__numberedAccordion}>3</span>
                    <span>Individually wrapped comfort coils</span>
                </span>
            ),
            content: (
                <ul>
                    <li>Maintains great airflow throughout the mattress</li>
                    <li>Disperses body heat so you sleep comfortably all night</li>
                </ul>
            )
        }
    ],
    'higher-quality-leather': [
        {
            title: 'Sustainably sourced',
            content: (
                <ul>
                    <li>Sustainably sourced Hides used are the by-products of free range, ethically-raised livestock.</li>
                </ul>
            )
        },
        {
            title: 'Eco-friendly processing',
            content: (
                <ul>
                    <li>Water used in processing is collected, treated, and recycled to conserve natural resources.</li>
                    <li>All organic leather waste is repurposed as fertilizer for agricultural use.</li>
                </ul>
            )
        },
        {
            title: 'REACH & Prop 65 compliant',
            content: (
                <ul>
                    <li>All chemicals used in the processing of our leathers are registered with EU REACH to ensure all substances meet their strict restrictions of human health and environmental safety.</li>
                    <li>Our leathers are compliant with Prop 65, notifying California residents of any significant amounts of listed chemicals present in any part of the manufacturing process.</li>
                </ul>
            )
        }
    ],
    [`${productCodes.CLASSIC_STARTER_BUNDLE}`]: [
        {
            title: 'Starting from scratch',
            isOpenOnRender: true,
            content: (
                <p>Whether you’re redoing a room in your home or moving to a new one, this bundle gives you the essential building blocks for lasting comfort.</p>
            )
        },
        {
            title: 'Creating an inviting space',
            content: (
                <p>If you’re looking to upgrade your guest room or bedroom, this bundle is the perfect start to create a comfy retreat.</p>
            )
        },
        {
            title: 'Your first “grown up” mattress',
            content: (
                <p>Congrats! You’re ready to upgrade to the luxurious sleep you deserve. You can’t go wrong with these tried-and-true staples as your introduction to Saatva.</p>
            )
        }
    ],
}

export default accordionData
