import React from 'react'
import * as productCodes from '@/constants/product-codes'
import ProductAccordion from '@/components/ProductAccordion'
import { Button } from '@saatva-bits/pattern-library.components.button'
import TabbedContentDisplay from '@/components/TabbedContentDisplay'
import MattressFaceOff from '@/components/MattressFaceOff'
import styles from './ImageByContent.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

const config = {
    [`${productCodes.LOOM_AND_LEAF}`]: {
        title: `Your best sleep starts with the best memory foam mattress`,
        content: () => [
            'Rest deeply with our ultra-premium memory foam mattress that strikes the perfect balance of comfort, support, and luxury.'
        ],
        imageFolder: 'generic/bed-woman',
        imageName: 'bed-woman-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`${productCodes.SAATVA_CONTOUR5}`]: {
        title: `The world’s most luxurious memory foam mattress`,
        content: () => [
            'At the heart of Contour5 is three plush inches of our ultra-premium 5lb memory foam for indulgent body-hugging comfort that can’t be replicated by a bed in a box.'
        ],
        imageFolder: 'generic/bed-woman',
        imageName: 'bed-woman-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`${productCodes.SAATVA_CONTOUR5}-coolVent`]: {
        title: `Stay comfy and rested with our CoolVent™ System`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SAATVA_CONTOUR5} key="coolVentAccordion" classNameModifier={'bgContrast1'} className={styles.tanAccordion} />
        ],
        imageFolder: 'generic/saatva-contour5-closer-look',
        imageName: 'saatva-contour5-closer-look-1-1.jpg',
        imageAlt: 'Mattress layers of our CoolVent system'
    },
    [`${productCodes.LOOM_AND_LEAF}--memory-foam`]: {
        title: `A memory foam mattress that's better for our planet`,
        content: () => [
            'When we say Loom & Leaf is an ultra-premium memory foam mattress, we don’t just stop at luxurious craftsmanship and comfort. We make sure it’s as good for our environment as it is for your sleep.',
            <ProductAccordion contentKey={productCodes.LOOM_AND_LEAF} key="memoryFoamAccordion" classNameModifier={'bgContrast1'} />
        ],
        imageFolder: 'generic/hand-memory-foam',
        imageName: 'hand-memory-foam-1-1.jpg',
        imageAlt: 'A hand touching a memory foam mattress'
    },
    [`${productCodes.ZENHAVEN}`]: {
        title: `Pure comfort starts with pure materials`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'generic/pure-comfort-organic',
            'generic/pure-comfort-natural',
            'generic/pure-comfort-nontoxic'
        ],
        imageName: [
            'pure-comfort-organic-1-1.jpg',
            'pure-comfort-natural-1-1.jpg',
            'pure-comfort-nontoxic-1-1.jpg'
        ],
        imageAlt: 'A pure comfort generic image'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: {
        title: `Pure comfort starts with pure materials`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'generic/pure-comfort-organic',
            'generic/pure-comfort-natural',
            'generic/pure-comfort-nontoxic'
        ],
        imageName: [
            'pure-comfort-organic-1-1.jpg',
            'pure-comfort-natural-1-1.jpg',
            'pure-comfort-nontoxic-1-1.jpg'
        ],
        imageAlt: 'A pure comfort generic image'
    },
    [`drift-away`]: {
        title: `Drift away on layers of pure, organic natural latex foam`,
        content: () => [
            'Our eco-friendly, ultra-cooling premium latex mattress features a proprietary design for the most buoyant feel imaginable.'
        ],
        imageFolder: 'generic/overview-intro',
        imageName: 'overview-intro-1-1.jpg',
        imageAlt: 'Woman sits comfortably on the memory foam mattress'
    },
    [`made-to-order`]: {
        title: 'Made to order, never&nbsp;stuffed&nbsp;in&nbsp;a box',
        content: () => [
            `Every Saatva mattress is handcrafted and assembled when you order it. Unlike other internet mattresses, we'd never dream of compressing our premium beds into a small box. Doing so can reduce its lifespan and compromise your comfort.`,
            <Button key='madeToOrderButton' kind='primary' className='u-paddingHorizontal--2dot5xl' href='https://pages.saatva.com/saatva-vs-bed-in-a-box' target='_blank'>See why we’re not a bed in a box</Button>
        ],
        imageFolder: 'generic/factory-mattress',
        imageName: 'factory-mattress-1-1.jpg',
        imageAlt: 'Woman sewing a mattress'
    },
    [`${productCodes.BUNK_TRUNDLE_YOUTH}`]: {
        title: 'Upgrade your child’s or guest room like never before',
        content: () => ['Unlike bunk bed mattresses made of cheap foam with minimal support, ours boasts all the comfort features of a luxury Saatva mattress at just 6” thick. Handcrafted, never stuffed in a box, delivered free to your room.'],
        imageFolder: 'products/bunk-trundle-youth/bunk-trundle-hero',
        imageName: 'bunk-trundle-hero-1-1.jpg',
        imageAlt: 'Child on Saatva Bunk and Trundle mattress'
    },
    [`${productCodes.SAATVA_YOUTH}`]: {
        title: 'Great days start with great sleep on the best mattress for kids',
        content: () => ['Our dual-sided youth mattress is specifically designed to help growing kids ages 3 to 12 reach their full potential through great sleep.'],
        imageFolder: 'products/saatva-youth/girl',
        imageName: 'saatva-youth-girl-1-1.jpg',
        imageAlt: 'Child on Saatva Youth mattress'
    },
    [`${productCodes.CRIB_MATTRESS}`]: {
        title: 'Give a bundle of comfort to your bundle of joy with the best crib mattress',
        content: () => ['Our handcrafted nontoxic crib mattress is designed with the safety and unique sleep needs of babies and toddlers in mind.'],
        imageFolder: 'products/crib-mattress/hero',
        imageName: 'saatva-crib-hero-1-1.jpg',
        imageAlt: 'Crib Mattress hero image'
    },
    [`${productCodes.ZENHAVEN}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.ZENHAVEN} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.SAATVA_LATEX_HYBRID} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}--savor-healthier`]: {
        title: `Savor healthier, cooler sleep with organic natural latex foam`,
        content: () => [
            'Our eco-friendly, ultra-cooling latex hybrid  combines the buoyant feel of natural latex coupled with the responsive support of an innerspring.'
        ],
        imageFolder: 'products/saatva-latex-hybrid/above',
        imageName: 'saatva-latex-hybrid-above-1-1.jpg',
        imageAlt: 'Latex Hybrid savor-healthier'
    },
    [`${productCodes.CRIB_MATTRESS}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.CRIB_MATTRESS} key="cribLatexAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_HD}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.SAATVA_HD} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SAATVA_HD}`]: {
        title: `The best mattress for heavier bodies`,
        content: () => [
            'Designed to support sleepers up to 500 pounds, our proprietary heavy-duty mattress balances luxurious comfort with unrivaled durability for every body type.'
        ],
        imageFolder: 'products/saatva-hd/above',
        imageName: 'saatva-hd-above-1-1.jpg',
        imageAlt: 'Saatva HD Mattress'
    },
    [`${productCodes.THE_SAATVA_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.THE_SAATVA_PILLOW} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.LATEX_MATTRESS_TOPPER}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.LATEX_MATTRESS_TOPPER} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.ORGANIC_QUILTED_MATTRESS_TOPPER} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.ORGANIC_QUILTED_PILLOW}--why-natural`]: {
        title: `Why natural latex?`,
        content: () => [
            'There’s nothing quite like the buoyant, pressure-free support of natural latex. Our latex goes through a washing process to remove all allergy-causing proteins, so all you’re left with is pure, lasting comfort.',
            <ProductAccordion contentKey={productCodes.ORGANIC_QUILTED_PILLOW} key="memoryFoamAccordion" />
        ],
        imageFolder: 'generic/why-natural-latex',
        imageName: 'why-natural-latex-1-1.jpg',
        imageAlt: 'Natural latex mattress material'
    },
    [`${productCodes.SCENTED_CANDLES}`]: {
        title: `Explore the aromatherapy benefits of our signature scented candles`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SCENTED_CANDLES} key="scentedCandlesAccordion" />
        ],
        imageFolder: 'products/scented-candles/lifestyle',
        imageName: 'scented-candles-lifestyle-1-1.jpg',
        imageAlt: 'Scented Candles'
    },
    [`${productCodes.SCENTED_VOTIVES}`]: {
        title: `Explore the aromatherapy benefits of our signature scented candles`,
        content: () => [
            <ProductAccordion contentKey={productCodes.SCENTED_VOTIVES} key="scentedVotivesAccordion" />
        ],
        imageFolder: 'products/scented-votives/lifestyle',
        imageName: 'scented-votives-lifestyle-1-1.jpg',
        imageAlt: 'Scented Votives'
    },
    [`${productCodes.FOUNDATION}`]: {
        title: 'Upgrade your bed setup with our premium foundation or bunkie board',
        content: () => [
            'Designed to be an elevated take on traditional box springs and bunkie boards, our foundations and 2” bunkie board maximize the comfort and lifespan of your mattress.'
        ],
        imageFolder: 'generic/bed-woman-2',
        imageName: 'bed-woman-2-1-1.jpg',
        imageAlt: 'Woman sitting on bed'
    },
    [`${productCodes.SAATVA_RX}`]: {
        title: 'The best mattress for chronic back & joint conditions',
        content: () => [
            'Rx offers therapeutic support and unparalleled pressure relief with an innovative support core that cradles your body in weightless comfort.'
        ],
        imageFolder: 'products/saatva-rx/above',
        imageName: 'saatva-rx-above-1-1.jpg',
        imageAlt: 'Saatva Rx Mattress'
    },
    [`${productCodes.SOLAIRE}`]: {
        title: 'Discover the power of personalized sleep comfort',
        content: () => [
            'Why settle when you can customize your firmness with a push of a button? Premium cooling layers help you further ease into luxurious comfort.'
        ],
        imageFolder: 'products/solaire/room-angle-raised/upper-flex',
        imageName: 'solaire-room-angle-raised-upper-flex-1-1.jpg',
        imageAlt: 'Solaire Upper-Flex Mattress'
    },
    [`${productCodes.SOLAIRE}--consumer-reports`]: {
        title: 'Mattress Face-Off:\nSaatva\u00A0vs.\u00A0Sleep Number',
        content: () => [
            <MattressFaceOff key='solaire-consumer-reports' />
        ],
        imageFolder: 'generic/solaire-consumer-reports',
        imageName: 'solaire-consumer-reports-1-1.png',
        imageAlt: 'Mattress Face-Off: Saatva vs. Sleep Number'
    },
    [`${productCodes.SOLAIRE}--personalized-comfort`]: {
        title: `Get even more personalized comfort options with our Saatva Adjustable Base Plus`,
        hasMultipleImages: true,
        content: TabbedContentDisplay,
        imageFolder: [
            'products/adjustable-base-plus/couple',
            'products/adjustable-base-plus/raised'
        ],
        imageName: [
            'adjustable-base-plus-couple-1-1.jpg',
            'adjustable-base-plus-raised-1-1.jpg'
        ],
        imageAlt: 'Images of the Solaire mattress with our Saatva Adjustable Base Plus'
    },
    [`${productCodes.SOLAIRE}--ultimate-sleep-comfort`]: {
        title: `Your ultimate sleep comfort at your fingertips`,
        content: () => [
            <p key='remote-paragraph'>Get your most effortless sleep night after night with just a touch of the remote (included for both sides).</p>,
            <p key='link-paragraph'><a className='t-link' href='https://www.saatva.com/blog/solaire-adjustable-firmness-mattress/' rel='noopener noreferrer'><SvgSprite spriteID='icon-article-link' className={styles.articleLink} />What to Expect After Buying a Solaire Adjustable Firmness Mattress</a></p>,
            <ProductAccordion contentKey={productCodes.SOLAIRE} key="solaireSleepComfort" classNameModifier={'bgContrast2'} className='u-bgColor--contrast-2' />
        ],
        imageFolder: 'products/solaire/remote-features',
        imageName: 'solaire-remote-features-1-1.jpg',
        imageAlt: 'A person holding a Solaire mattress remote'
    },
    'therapeutic-support-core': {
        title: 'Maximum relief starts at the core',
        content: () => [
            'A closer look inside our groundbreaking Therapeutic Support Core™',
            <ProductAccordion contentKey={productCodes.SAATVA_RX} key="therapeuticSupportAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'generic/support-core-closer-look',
        imageName: 'support-core-closer-look-1-1.jpg',
        imageAlt: 'Triple-phase LuxeCool™ system'
    },
    [`${productCodes.DOG_BED}--microcoil`]: {
        title: 'Micro coils, big comfort',
        content: () => [
            `To build a dog bed like no other, we started at the core.`,
            <p key="microcoil1">Our durable <span className="t-fontWeight--normal">micro-coil support</span> core helps keep your pet’s back happy, keeps them cooler, and makes moving around in bed much easier.</p>,
            <p key="microcoil2" className="t-italic t-bodySm">*For dogs that are heavy chewers, keep an eye on the bed cover’s condition to ensure they don’t chew down to internal parts and to prevent damage to the bed's core.</p>
        ],
        imageFolder: 'products/dog-bed/colorway-angle',
        imageName: 'dog-bed-colorway-angle-1-1.jpg',
        imageAlt: 'Saatva Dog Bed micro-coil support'
    },
    [`${productCodes.DOG_BED}--cozy`]: {
        title: 'Cozy, cuddly & easy to clean',
        content: () => [
            <p key="cozy0">Your pet’s bed can look <span className="t-italic">ruff</span> after a while. We made our ultra-luxe, <span className="t-fontWeight--normal">high-performance fabric cover</span> resistant to spills and stains, so cleanup is a breeze.</p>,
            <p key="cozy1">Need a backup? We have <button className={styles.linkButton} onClick={() => window.scrollTo(0, 0)}>replacement bed covers</button> available.</p>
        ],
        imageFolder: 'products/dog-bed/cover/taupe',
        imageName: 'dog-bed-cover-taupe-1-1.jpg',
        imageAlt: 'Saatva Dog Bed Covers'
    },
    [`${productCodes.DOG_BED}--layer`]: {
        title: 'The comfort your pet deserves, built into every layer',
        content: () => [
            <ProductAccordion contentKey={productCodes.DOG_BED} key="dogBedAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'products/dog-bed/cutaway-numbered',
        imageName: 'dog-bed-cutaway-numbered-1-1.jpg',
        imageAlt: 'Saatva Dog Bed Layers'
    },
    [`${productCodes.SAATVA_CLASSIC}`]: {
        title: 'Enjoy blissful sleep on a tried-and-true favorite',
        content: () => [
            'Our award-winning innerspring offers dreamy comfort inspired by high-end luxury hotels with responsive support for every sleeper.'
        ],
        imageFolder: 'products/saatva-classic/above',
        imageName: 'saatva-classic-above-1-1.jpg',
        imageAlt: 'Saatva Classic Mattress'
    },
    [`${productCodes.MEMORY_FOAM_HYBRID}`]: {
        title: `A memory foam hybrid that's the best of both worlds`,
        content: () => [
            'Enjoy the memory foam feel you love with responsive innerspring support and innovative cooling for truly restorative sleep.'
        ],
        imageFolder: 'products/memory-foam-hybrid/above',
        imageName: 'memory-foam-hybrid-above-1-1.jpg',
        imageAlt: 'Memory Foam Hybrid Mattress'
    },
    'triple-phase': {
        title: 'Sleep cooler with our triple-phase LuxeCool™ system',
        content: () => [
            <ProductAccordion contentKey={productCodes.MEMORY_FOAM_HYBRID} key="therapeuticSupportAccordion" className={styles.tanAccordion} />
        ],
        imageFolder: 'products/memory-foam-hybrid/cutaway',
        imageName: 'memory-foam-hybrid-cutaway-1-1.jpg',
        imageAlt: 'Triple-phase LuxeCool™ system'
    },
    'higher-quality-leather': {
        title: 'Higher standards for higher quality leather',
        content: () => [
            <ProductAccordion contentKey={'higher-quality-leather'} key="higherQualityLeatherAccordion" />
        ],
        imageFolder: 'generic/materials-quality-leather',
        imageName: 'materials-quality-leather-1-1.jpg',
        imageAlt: 'Higher standards for higher quality leather'
    },
    [`${productCodes.CLASSIC_STARTER_BUNDLE}`]: {
        title: 'Better together',
        content: () => [
            'Our best-selling Saatva Classic is a standout on its own, but even better paired with our most popular add ons that enhance support and everyday resilience.'
        ],
        imageFolder: 'products/classic-starter-bundle/room-above',
        imageName: 'classic-starter-bundle-room-above-1-1.jpg',
        imageAlt: 'Image with the items bundled'
    },
    [`${productCodes.CLASSIC_STARTER_BUNDLE}--your-best-sleep`]: {
        title: ` Your best sleep starts here`,
        content: () => [
            'The Classic Starter Bundle is ideal for:',
            <ProductAccordion contentKey={productCodes.CLASSIC_STARTER_BUNDLE} key="classicStarterBundleAccordion" classNameModifier='bgContrast2' className='u-bgColor--contrast-2' />
        ],
        imageFolder: 'generic/your-best-sleep',
        imageName: 'classic-bundle-pdp-best-sleep-1-1.jpg',
        imageAlt: 'your best sleep with out classic starter bundle'
    },
    [`${productCodes.KANAN_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/kanan-bedroom-rug/colorways',
        imageName: 'kanan-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.TERNA_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Hand tufted by skilled artisans to create its stunning pattern and plush feel, the Terna is the ultimate blend of quality and a great price point.'],
        imageFolder: 'products/terna-bedroom-rug/colorways',
        imageName: 'terna-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.ADAMAS_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/adamas-bedroom-rug/colorways',
        imageName: 'adamas-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.CIRRUS_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/cirrus-bedroom-rug/colorways',
        imageName: 'cirrus-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.MYRA_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/myra-bedroom-rug/colorways',
        imageName: 'myra-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
    [`${productCodes.NAVI_BEDROOM_RUG}`]: {
        title: 'Expertly handcrafted for lasting comfort & luxury',
        content: () => ['Handwoven individually by skilled artisans using a traditional hand-operated loom technique, this rug is crafted to look and feel great for years to come.'],
        imageFolder: 'products/navi-bedroom-rug/colorways',
        imageName: 'navi-bedroom-rug-colorways-1-1.jpg',
        imageAlt: 'Close-up of overlapping rugs'
    },
}

export { config }
